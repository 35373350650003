import '../styles/styles.css';
import RouterComponent from './RouterComponent';

function App() {
  return (
    <RouterComponent />
  );
}

export default App;
